<template>
  <div>
    <div class="bg-offset">
      <div class="container space-top-2">
        <div class="row">
          <div class="col-lg-6 pr-lg-3 pr-xl-6">
            <h1 class="mb-4">{{ $t('BookDemoPage.Title') }}</h1>
            <p>{{ $t('BookDemoPage.Description') }}</p>
            <!-- > lg only -->
            <div class="space-top-2 d-none d-lg-block">
              <h4 class="mb-4">{{ $t('BookDemoPage.DetailSection.Title') }}</h4>
              <div class="media mb-4">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body text-dark">
                  {{ $t('BookDemoPage.DetailSection.Detail1') }}
                </div>
              </div>
              <div class="media mb-4">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body text-dark">
                  {{ $t('BookDemoPage.DetailSection.Detail2') }}
                </div>
              </div>
              <div class="media mb-4">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body text-dark">
                  {{ $t('BookDemoPage.DetailSection.Detail3') }}
                </div>
              </div>
            </div>
          </div>
          <!-- Calendly Form -->
          <div class="col-lg-6 mt-5 mt-lg-0">
            <div class="card shadow rounded w-100 w-md-90 w-lg-100 mx-auto">
              <div class="calendly-inline-widget " data-url="https://calendly.com/fancs/demo-session-openapihub" style="width:100%;height:1025px;" />
            </div>
          </div>

          <!-- < lg only-->
          <div class="col-lg">
            <div class="space-top-2 d-lg-none">
              <h4 class="mb-4">{{ $t('BookDemoPage.DetailSection.Title') }}</h4>
              <div class="media mb-4">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body text-dark">
                  {{ $t('BookDemoPage.DetailSection.Detail1') }}
                </div>
              </div>
              <div class="media mb-4">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body text-dark">
                  {{ $t('BookDemoPage.DetailSection.Detail2') }}
                </div>
              </div>
              <div class="media mb-4">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body text-dark">
                  {{ $t('BookDemoPage.DetailSection.Detail3') }}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div style="background-color:#f3f9fe">
      <!-- eslint-disable -->
      <div class="container space-2 space-bottom-3">
        <div class="text-center mb-4">
          <h2 class="divider divider-xs divider-text">{{ $t('OW01HomePage.HeroSection.TrustedBy') }}</h2>
        </div>
        <Slick ref="CommunityProvidersCarousel" :options="slickOptions" class="align-items-center">
          <div v-for="(item, index) in providers" :key="index" class="js-slide">
            <img class="max-w-19rem mx-auto" :src="require(`@/assets/img/api-providers/${item.image}`)">
          </div>
        </Slick>
      </div>
      <!-- eslint-enable -->
    </div>

  </div>
</template>

<script>
import 'bootstrap'
import Slick from 'vue-slick'

export default {
  name: 'BookDemo',
  components: {
    Slick
  },
  data() {
    return {
      providers: [
        {
          image: 'fiberconnect-logo.svg'
        },
        {
          image: 'taptab-logo.svg'
        },
        {
          image: 'stemedia-logo.svg'
        },
        {
          image: 'openimagelab-logo.svg'
        },
        {
          image: 'atchative-logo.svg'
        },
        {
          image: 'benovelty-logo.svg'
        }
      ],
      slickOptions: {
        slidesToShow: 5,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        prevArrow: '<span class="fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-n3 d-none" />',
        nextArrow: '<span class="fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-n3 d-none" />',
        responsive: [{
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 992,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        }, {
          breakpoint: 576,
          settings: {
            slidesToShow: 2
          }
        }]
      }
    }
  },
  beforeUpdate() {
    if (this.$refs.CommunityProvidersCarousel) {
      this.$refs.CommunityProvidersCarousel.destroy()
    }
  },
  updated() {
    this.$nextTick(function() {
      if (this.$refs.CommunityProvidersCarousel) {
        this.$refs.CommunityProvidersCarousel.create(this.slickOptions)
      }
    })
  },
  mounted() {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    document.head.appendChild(recaptchaScript)
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: 'Book a Demo | %s',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/book-demo' }
      ],
      meta: [
        { name: 'description', content: 'OpenAPIHub offers a full suite of features and tools to help boosting your API business. Learn how in a free demo with one of our product experts.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'Book a Demo | OpenAPIHub' },
        { property: 'og:description', content: 'OpenAPIHub offers a full suite of features and tools to help boosting your API business. Learn how in a free demo with one of our product experts.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/book-demo' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~slick-carousel/slick/slick.css';

.bg-offset {
    background-image: url('../../assets/svg/components/bg-wave.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

</style>
